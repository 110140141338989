import axios from "axios";

export const doGetInstrumentList = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/advancesearch", params, {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetInstrumentListNew = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/advancesearchv2", params.filters, {
        baseURL: process.env.REACT_APP_API_URL,
        signal: params.signal
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetInstrumentTypes = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/search/instrumenttype", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get trs document
export const doGetInstrumentDocument = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/document", params, {
        responseType: "arraybuffer",
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

// get pdf comments
export const doGetPdfComments = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/getinstrumentfeedback", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
        reject(error);
      });
  });
};

// add pdf comments
export const doAddPdfComments = (params) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/addinstrumentfeedback", params)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.log("ERROR", error);
        reject(error);
      });
  });
};

export const doFetchPreviousFilters = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/search/previoussearch", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doreportIssuePDF = (payload) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/addreporterror", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const doGetErrorReportData = (payload) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/searchreporterrordata", payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export const doGetRegulatoryAppsOrders = () => {
  return new Promise(function (resolve, reject) {
    axios
      .get("/search/getregulatoryappsorders", {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetRegulatoryAppsOrdersNew = (payload) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/searchregulatoryappsorders", payload, {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doGetWellData = (payload) => {
  return new Promise(function (resolve, reject) {
    axios
      .post("/search/getwelldata", payload, {
        baseURL: process.env.REACT_APP_API_URL,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const doDeleteReportError = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .delete("/search/deletereporterror", {
        baseURL: process.env.REACT_APP_API_URL,
        headers: { "Content-Type": "application/json" },
        data: { rid: String(payload.rid) },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};


