import { useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";

import "react-tagsinput/react-tagsinput.css";

import {
    udpateUserDetails,
} from "../../actions/userManagementAction";

const EditUser = ({
    userDetails,
    udpateUserDetails,
    onCancel,
    theme
}) => {
    const defaultValues = useMemo(() => ({
        username: userDetails.username ?? '',
        pwd: userDetails.pwd ?? '',
        fn: userDetails.fn ?? '',
        ln: userDetails.ln ?? '',
        co: userDetails.co ?? '',
        ad: userDetails.ad ?? '',
        ph: userDetails.ph ?? '',
        iau: userDetails.iau ? 1 : 0,
    }), [userDetails]);
    const [user, setUser] = useState(defaultValues);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setUser(defaultValues);
    }, [defaultValues]);

    const onSubmit = () => {
        const formErrors = validate();
        if (Object.keys(formErrors).length === 0) {
            udpateUserDetails({
                Id: userDetails.Id,
                iau: user.iau === 1,
                ...user
            })
            onCancel();
        } else {
            setErrors(formErrors);
        };
    };

    const inputsHandler = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    }

    const validate = () => {
        const errors = {};
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!user.username) {
            errors.username = 'Username is required';
        } else if(!emailRegex.test(user.username)) {
            errors.username = 'Username is not a valid Email';
        }
        if (!user.fn) {
            errors.fn = 'First Name is required';
        }
        if (!user.ln) {
            errors.ln = 'Last Name is required';
        }
        if (!userDetails.Id && !user.pwd) {
            errors.pwd = 'Password is required';
        }
        return errors;
    }

    return (
        <div className={`edit-user${theme === 'dark' ? '-dark' : ''}`}>
            <div className="content">
                <label>Username</label>
                <div>
                    <input
                        placeholder="Username"
                        name="username"
                        className="form-control bg-transparent"
                        type="text"
                        autoComplete="off"
                        value={user.username}
                        onChange={inputsHandler}
                    />
                    {errors.username && (<span className="error">{errors.username}</span>)}
                </div>
                <label>First Name</label>
                <div>
                    <input
                        placeholder="First Name"
                        name="fn"
                        className="form-control bg-transparent"
                        type="text"
                        autoComplete="off"
                        value={user.fn}
                        onChange={inputsHandler}
                    />
                    {errors.fn && (<span className="error">{errors.fn}</span>)}
                </div>
                <label>Last Name</label>
                <div>
                    <input
                        placeholder="Last Name"
                        name="ln"
                        className="form-control bg-transparent"
                        type="text"
                        autoComplete="off"
                        value={user.ln}
                        onChange={inputsHandler}
                    />
                    {errors.ln && (<span className="error">{errors.ln}</span>)}
                </div>
                <label>Company</label>
                <div>
                    <input
                        placeholder="Company"
                        name="co"
                        className="form-control bg-transparent"
                        type="text"
                        autoComplete="off"
                        value={user.co}
                        onChange={inputsHandler}
                    />
                </div>
                <label>Address</label>
                <div>
                    <input
                        placeholder="Address"
                        name="ad"
                        className="form-control bg-transparent"
                        type="text"
                        autoComplete="off"
                        value={user.ad}
                        onChange={inputsHandler}
                    />
                </div>
                { !userDetails.Id && (
                <>
                    <label>Password</label>
                    <div>
                        <input
                            placeholder="Password"
                            name="pwd"
                            className="form-control bg-transparent"
                            type="password"
                            autoComplete="new-password"
                            value={user.pwd}
                            onChange={inputsHandler}
                        />
                    {errors.pwd && (<span className="error">{errors.pwd}</span>)}
                    </div>
                </>
                )}
                <label>Phone Number</label>
                <div>
                    <input
                        placeholder="Phone Number"
                        name="ph"
                        className="form-control bg-transparent"
                        type="text"
                        autoComplete="off"
                        value={user.ph}
                        onChange={inputsHandler}
                    />
                </div>
                <label>Is Admin?</label>
                <div>
                <select
                    placeholder="Is Admin"
                    name="iau"
                    value={user.iau}
                    onChange={inputsHandler}
                    className="form-control bg-transparent"
                    style={{appearance: "auto", padding: "8px"}}
                >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                </select>
                </div>
            </div>
            <div className="buttons">
                <button className="btn btn-primary" onClick={onSubmit}>
                    Save
                </button>
                <button className="btn btn-info" onClick={onCancel}>
                    Cancel
                </button>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    userDetails: state.userManagement.userDetails,
    theme: state.user.theme
});

const mapDispatchToProps = (dispatch) => ({
    udpateUserDetails: (user) => dispatch(udpateUserDetails(user)),
});

const EditUserContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(EditUser);

export default EditUserContainer;