/**
 * OKCR Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import * as types from "../actions/types";
import * as api from "../apis/advancedSearch";
import * as instrumentApis from "../apis/instrument";
import { toast } from "react-toastify";


export function* getListing({ payload }) {
  try {
    const res = yield call(api.doGetInstrumentList, payload);
    if (res) {
      yield put({
        type: types.ADVANCED_SEARCH_SUCCESS,
        payload: { instrumentList: res },
      });
    } else {
      yield put({
        type: types.ADVANCED_SEARCH_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.ADVANCED_SEARCH_FAILURE,
      payload: { err },
    });
  }
}

function getFilterType(filters) {
  // Check if date filters are set (either or both dates)
  const hasDateFilters = (filters.from_date || filters.to_date);

  // Check if any of the other filters have values
  const hasOtherFilters = (
      filters.trs || filters.grantees || filters.grantors || 
      filters.instrument_type || filters.county || 
      filters.book || filters.page
  );

  // Determine the filter type based on the conditions
  if (hasDateFilters && hasOtherFilters) {
      return 'TD'; // Date and other filters both have values
  } else if (hasDateFilters) {
      return 'DD'; // Only date filters have values
  } else if (hasOtherFilters) {
      return 'TT'; // Only other filters have values
  } else {
      return 'TD'; // No filters set
  }
}

export function* getListingNew({ payload }) { 
  try {
    const res = yield call(api.doGetInstrumentListNew, payload);
    if (res) {
      yield put({
        type: types.TRIGGER_LOCAL_GRID_FILTER,
      })
      // if (res.length) {
        const { filters } = payload;
        const filterType = getFilterType(payload.filters);
          // Create the new payload for the API
          const apiPayload = {
            fromdate: filters.from_date, // Map to API's required format
            todate: filters.to_date,    // Map to API's required format
            sbt: filterType
          };
          // Dispatch action to trigger the new API with the formatted payload
          yield all([
            put({
              type: types.GET_REGULATORY_APPS_ORDERS_DATA_REQUEST_NEW,
              payload: apiPayload,
            }),
            put({
              type: types.GET_WELL_DATA_REQUEST,
            }),
          ]);
      // }

      // Dispatch success action for advanced search
      yield put({
        type: types.ADVANCED_SEARCH_NEW_SUCCESS,
        payload: { payload, instrumentList: res },
      });
    } else {
      // Dispatch failure action
      yield put({
        type: types.ADVANCED_SEARCH_NEW_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    // Dispatch failure action with error
    yield put({
      type: types.ADVANCED_SEARCH_NEW_FAILURE,
      payload: { err },
    });
  }
}

export function* getInstrumentTypes() {
  try {
    const res = yield call(api.doGetInstrumentTypes);
    if (res) {
      yield put({
        type: types.INSTRUMENT_TYPE_LIST_SUCCESS,
        payload: { instrumentTypes: res },
      });
    } else {
      yield put({
        type: types.INSTRUMENT_TYPE_LIST_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.INSTRUMENT_TYPE_LIST_FAILURE,
      payload: { err },
    });
  }
}

export function* getPdfComments({payload}) {
  try {
    const res = yield call(api.doGetPdfComments, payload);
    if (res) {
      yield put({
        type: types.GET_PDF_COMMENTS_SUCCESS,
        payload: res,
      });
    } else {
      yield put({
        type: types.GET_PDF_COMMENTS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_PDF_COMMENTS_FAILURE,
      payload: { err },
    });
  }
}

export function* addPdfComments({payload}) {
  try {
    const res = yield call(api.doAddPdfComments, payload);
    if (res) {
      yield put({
        type: types.ADD_PDF_COMMENTS_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: types.ADD_PDF_COMMENTS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.ADD_PDF_COMMENTS_FAILURE,
      payload: { err },
    });
  }
}

export function* fetchPreviousFiltersSaga() {
  try {
    const res = yield call(api.doFetchPreviousFilters);
    if (res) {
      yield put({
        type: types.FETCH_PREVIOUS_FILTERS_SUCCESS,
        payload: res,
      });
    } else {
      yield put({
        type: types.FETCH_PREVIOUS_FILTERS_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.FETCH_PREVIOUS_FILTERS_FAILURE,
      payload: { err },
    });
  }
}

// Get Instrument document
export function* getInstrumentDocumentSaga({ payload: { instrument} }) {
  try {
    const req = { county: instrument.co,
      recordeddate: instrument.requestedDate 
     };
    let apiMethod;
    if(instrument.hd) {
      req['instrument'] = instrument.iid;
      apiMethod = instrumentApis.doGetInstrumentDocument;
    } else {
      req['instrument_id'] = instrument.iid;
      apiMethod = api.doGetInstrumentDocument;
    }
    const pdfData = yield call(apiMethod, req);
    if (pdfData) {
      yield put({
        type: instrument.hd ? types.GET_ADV_INST_DOCUMENT_SUCCESS : types.DOWNLOAD_ADV_INST_DOCUMENT_SUCCESS,
        payload: { instrument, pdfData },
      });
    } else {
      yield put({
        type: types.GET_ADV_INST_DOCUMENT_FAILURE,
        payload: { err: { message: "Something went wrong" }, instrument },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_ADV_INST_DOCUMENT_FAILURE,
      payload: { err: { message: "Something went wrong" } , instrument},
    });
  }
}

export function* reportIssuePDF({ payload }) {
  try {
    const res = yield call(api.doreportIssuePDF, payload);
    if (res && res[0]?.success) {
      toast.success(res[0].success);
      yield put({
        type: types.REPORT_ERROR_PDF_SUCCESS,
        payload,
      });
    } else {
      yield put({
        type: types.REPORT_ERROR_PDF_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.REPORT_ERROR_PDF_FAILURE,
      payload: { err },
    });
  }
}

export function* getErrorReportData({ payload: { id } }) {
  try {
    const res = yield call(api.doGetErrorReportData, { rid: id });
    if (res[0]) {
      yield put({
        type: types.GET_ERROR_REPORT_DATA_SUCCESS,
        payload: res[0],
      });
    } else {
      yield put({
        type: types.GET_ERROR_REPORT_DATA_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_ERROR_REPORT_DATA_FAILURE,
      payload: { err },
    });
  }
}

export function* fetchRegulatoryAppsOrdersSaga() {
  try {
    const res = yield call(api.doGetRegulatoryAppsOrders);  // Call the API
    console.log("Inspect response structure:", res);
    if (res) {
      yield put({
        type: types.GET_REGULATORY_APPS_ORDERS_DATA_SUCCESS,  // Dispatch success action
        payload: { regulatoryAppsOrders: res },  // Pass the data as payload
      });
    } else {
      yield put({
        type: types.GET_REGULATORY_APPS_ORDERS_DATA_FAILURE,  // Dispatch failure action
        payload: { err: { message: "Something went wrong" } },  // Error message in case of failure
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_REGULATORY_APPS_ORDERS_DATA_FAILURE,  // Dispatch failure action
      payload: { err },  // Pass the caught error
    });
  }
}

export function* fetchRegulatoryAppsOrdersSagaNew({ payload }) {
  try {
    // Call the API
    const res = yield call(api.doGetRegulatoryAppsOrdersNew, payload);
    if (res) {
      yield put({
        type: types.GET_REGULATORY_APPS_ORDERS_DATA_SUCCESS, // Dispatch success action
        payload: { regulatoryAppsOrders: res }, // Pass the data as payload
      });
    } else {
      // Handle unexpected response structure
      yield put({
        type: types.GET_REGULATORY_APPS_ORDERS_DATA_FAILURE, // Dispatch failure action
        payload: { err: { message: "Unexpected response structure" } }, // Error message
      });
    }
  } catch (err) {
    console.error("Error in fetchRegulatoryAppsOrdersSagaNew:", err);
    yield put({
      type: types.GET_REGULATORY_APPS_ORDERS_DATA_FAILURE, // Dispatch failure action
      payload: { err: { message: err.message || "An unknown error occurred." } }, // Pass the caught error
    });
  }
}

export function* fetchWellDataSaga({ payload }) {
  try {
    const res = yield call(api.doGetWellData, payload);
    if (res) {
      yield put({
        type: types.GET_WELL_DATA_SUCCESS,
        payload: { wellData: res },
      });
    } else {
      yield put({
        type: types.GET_WELL_DATA_FAILURE,
        payload: { err: { message: "Unexpected response structure" } },
      });
    }
  } catch (err) {
    console.error("fetchWellDataSaga error:", err);
    yield put({
      type: types.GET_WELL_DATA_FAILURE,
      payload: { err: { message: err.message || "An unknown error occurred." } },
    });
  }
}

export function* deleteReportError({ rid }) {
  try {
    const res = yield call(api.doDeleteReportError, rid);
    yield put({ type: types.DELETE_REPORT_ERROR_SUCCESS, rid });
    toast.success(res?.success || "Report error deleted successfully!");
  } catch (error) {
    yield put({ type: types.DELETE_REPORT_ERROR_FAILURE, error });
    toast.error(error?.message || "Failed to delete report error. Please try again.");
  }
}





// OKCR Saga
export default function* advancedSearchSaga() {
  yield all([
    takeEvery(types.ADVANCED_SEARCH_REQUEST, getListing),
    takeEvery(types.ADVANCED_SEARCH_NEW_REQUEST, getListingNew),
    takeEvery(types.INSTRUMENT_TYPE_LIST_REQUEST, getInstrumentTypes),
    takeEvery(types.GET_ADV_INST_DOCUMENT_REQUEST, getInstrumentDocumentSaga),
    takeEvery(types.DOWNLOAD_ADV_INST_DOCUMENT_REQUEST, getInstrumentDocumentSaga),
    takeEvery(types.GET_PDF_COMMENTS_REQUEST, getPdfComments),
    takeEvery(types.ADD_PDF_COMMENTS_REQUEST, addPdfComments),
    takeEvery(types.FETCH_PREVIOUS_FILTERS_REQUEST, fetchPreviousFiltersSaga),
    takeEvery(types.REPORT_ERROR_PDF_REQUEST, reportIssuePDF),
    takeEvery(types.GET_ERROR_REPORT_DATA_REQUEST, getErrorReportData),
    takeEvery(types.GET_REGULATORY_APPS_ORDERS_DATA_REQUEST, fetchRegulatoryAppsOrdersSaga),
    takeEvery(types.GET_REGULATORY_APPS_ORDERS_DATA_REQUEST_NEW, fetchRegulatoryAppsOrdersSagaNew),
    takeEvery(types.GET_WELL_DATA_REQUEST, fetchWellDataSaga),
    takeEvery(types.DELETE_REPORT_ERROR_REQUEST, deleteReportError)
  ]);
}