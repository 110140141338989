import { handleActions } from "redux-actions";
import moment from "moment-timezone";

const fromDate = moment(
    new Date(new Date().setDate(new Date().getDate() - 30))
).tz("UTC");
const toDate = moment(new Date()).tz("UTC");
const defaultState = {
	__persist: false,
	usersList: [],
	userLogList: [],
	errorLogList: [],
    defaultFilters: {
		fromdate: fromDate.format("Y-MM-DD"),
		todate: toDate.format("Y-MM-DD"),
		eventtype: "",
		username: "",
		userName: [],
		eventType: [],
	},
	defaultErrorFilters: {
		fromdate: fromDate.format("Y-MM-DD"),
		todate: toDate.format("Y-MM-DD"),
		userName: [],
		edA: [],
		countyA: [],
		co: "",
		ed: "",
		un: ""
	},
	reportReasons : [
		{ id: "wbp", name: "Wrong Book Page" },
		{ id: "wgg", name: "Wrong Grantee/Grantor" },
		{ id: "wl", name: "Wrong Legal" },
		{ id: "wit", name: "Wrong Instrument Type" },
		{ id: "wd", name: "Wrong Date" },
		{ id: "mp", name: "Missing Pages" },
		{ id: "po", name: "Pages out of Order"},
		{ id: "inl", name: "Instrument not loading"},
		{ id: "oth", name: "Other" },
	],
	error: null,
	loading: false
};

export default handleActions(
	{
		FETCH_USERS_LIST_REQUEST: (state) => {
			return {
				...state,
				loading: true,
				error: null,
			};
		},

        FETCH_USERS_LIST_SUCCESS: (state, { payload: usersList }) => {
            return {
                ...state,
                usersList,
            }
        },

        FETCH_USERS_LIST_FAILURE: (state, { payload: err }) => {
			return {
				...state,
                usersList: [],
				loading: false,
				error: err,
			};
		},

        FETCH_USER_LOG_REQUEST: (state) => {
			return {
				...state,
                userLogList: [],
				loading: true,
				error: null,
			};
		},

        FETCH_USER_LOG_SUCCESS: (state, { payload: userLogList }) => {
            const logs = userLogList.map((log) => {
                return {
                    ...log,
                    DateTimeEvent: log.DateTimeEvent ? new Date(log.DateTimeEvent) : null
                }
            })
            return {
                ...state,
                userLogList: logs,
                loading: false,
            }
        },

        FETCH_USER_LOG_FAILURE: (state, { payload: err }) => {
			return {
				...state,
                userLogList: [],
				loading: false,
				error: err,
			};
		},

		FETCH_ERROR_LOG_REQUEST: (state) => {
			return {
				...state,
                errorLogList: [],
				loading: true,
				error: null,
			};
		},

        FETCH_ERROR_LOG_SUCCESS: (state, { payload: errorLogList }) => {
            const logs = errorLogList.map((log) => {
                return {
                    ...log,
                    rt: log.rt ? new Date(log.rt) : null
                }
            })
            return {
                ...state,
                errorLogList: logs,
                loading: false,
            }
        },

        FETCH_ERROR_LOG_FAILURE: (state, { payload: err }) => {
			return {
				...state,
                errorLogList: [],
				loading: false,
				error: err,
			};
		},

		UPDATE_ERROR_DETAILS_SUCCESS: (state, { payload: res}) => {
			const errors = state.errorLogList.map(error => {
				if(error.Id === res.Id) {
					return {
						...res,
						rt: res.rt ? new Date(res.rt) : null
					}
				}
				return error;
			})
			return {
				...state,
				errorLogList: errors
			};
		},

		UPDATE_ERROR_DETAILS_FAILURE: (state, { payload: err }) => {
			return {
				...state,
				loading: false,
				error: err,
			};
		},

		DELETE_REPORT_ERROR_SUCCESS: (state, { rid }) => {
			const reportId = Number(rid.rid); 
			const filteredList = state.errorLogList.filter(item => item.Id !== reportId);
			return {
			  ...state,
			  errorLogList: [...filteredList],
			};
		}
		
		  

			
	},
	defaultState
);
