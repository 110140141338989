import { useState, useEffect } from "react";
import { connect } from "react-redux";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { MdEditNote, MdOutlineRemoveRedEye } from "react-icons/md";
import NavBarContainer from "../../components/common/navbar";
import Table from "../../components/common/table";
import SelectViewNew from "../../components/searchFilter/SelectViewNew";
import { getErrorLogList, getUsersList } from "../../actions/userLogAction";
import { getInstrumentPdf, getErrorReportData, getInstrumentTypes,deleteReportError } from "../../actions/advancedSearchAction";
import PdfModal from "../../components/common/pdfModal";
import { IoMdClose } from "react-icons/io";
import Modal from "../../components/common/modal";

function ErrorLog({
  errorLogList,
  reportReasons,
  defaultFilters,
  theme,
  getErrorLogList,
  loading,
  county,
  getUsersList,
  users,
  getInstrumentPdf,
  selectedPdf,
  getErrorReportData,
  reportedErrorData,
  getInstrumentTypes,
  deleteReportError
}) {
  const [filters, setFilters] = useState(defaultFilters);
  const [showModal, setShowModal] = useState(false);
  const [currentTab, setCurrentTab] = useState("reportedError");
  const [show, setShow] = useState(false);
  const [modalBody, setModalBody] = useState('');
  const [modalHeader, setModalHeader] = useState('');
  const [modalFooter, setModalFooter] = useState('');
  const loadingDiv = (
    <>
      {loading && <span className="ml-2"> Fetching Data. Please Wait... </span>}
    </>
  );

  useEffect(() => {
    getUsersList();
    getErrorLogList(defaultFilters);
    getInstrumentTypes();
  }, [getUsersList, getErrorLogList, defaultFilters, getInstrumentTypes]);

  const inputsHandler = (e) => {
    setFilters({ ...filters, [e.target.name]: e.target.value });
  };

  const cols = [
    {
      headerName: "Reported By",
      field: "username",
      width: 200,
      wrapHeaderText: true,
    },
    {
      headerName: "Reported Date Time",
      field: "rt",
      wrapHeaderText: true,
      width: 180,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) =>
        value instanceof Date
          ? value.toLocaleString("en-US", { timeZone: "UTC" })
          : "",
    },
    {
      headerName: "County",
      field: "co",
      width: 80,
      maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "Instrument Number",
      field: "iid",
      width: 130,
      wrapHeaderText: true,
    },
    {
      headerName: "Recorded Date",
      field: "rd",
      width: 130,
      wrapHeaderText: true,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
      },
    },
    {
      headerName: "Error Description",
      field: "ed",
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Comment",
      field: "cmt",
      minWidth: 350,
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "",
      resizable: false,
      minWidth: 90,
      maxWidth: 90,
      cellRenderer: ({ data }) => {
        let Icon = MdEditNote;
        if(data.st === 'Final') Icon = MdOutlineRemoveRedEye;
        return (
          <div style={{marginTop:"5px"}}>
        <Icon
          className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
          onClick={() => {
            getInstrumentPdf({
              ...data,
              hd: true
            });
            getErrorReportData(data.Id);
            setShowModal(true);
          }}
        />
         {currentTab === "reportedError" && (
          <IoMdClose
            className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
            onClick={() => {
              showConfirmationBox(data.Id)
            }}
          />
        )}
       </div>
      )},
    }
  ];

  const correctedCols = [
    {
      headerName: "Corrected By",
      field: "cb",
      width: 200,
      wrapHeaderText: true,
    },
    {
      headerName: "Corrected Date Time",
      field: "ct",
      wrapHeaderText: true,
      width: 180,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => {
        const date = new Date(value);
        return !isNaN(date.getTime()) // Check if valid date
          ? date.toLocaleString("en-US", { timeZone: "UTC" })
          : "";
      },
    },
    {
      headerName: "County",
      field: "co",
      width: 80,
      maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "Instrument Number",
      field: "iid",
      width: 130,
      wrapHeaderText: true,
    },
    {
      headerName: "Recorded Date",
      field: "rd",
      width: 130,
      wrapHeaderText: true,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
      },
    },
    {
      headerName: "Error Description",
      field: "ed",
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Comment",
      field: "cmt",
      minWidth: 350,
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "",
      resizable: false,
      minWidth: 90,
      maxWidth: 90,
      cellRenderer: ({ data }) => {
        let Icon = MdEditNote;
        if(data.st === 'Final') Icon = MdOutlineRemoveRedEye;
        return (
          <div style={{marginTop:"5px"}}>
        <Icon
          className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
          onClick={() => {
            getInstrumentPdf({
              ...data,
              hd: true
            });
            getErrorReportData(data.Id);
            setShowModal(true);
          }}
        />
         {currentTab === "reportedError" && (
          <IoMdClose
            className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
            onClick={() => {
              showConfirmationBox(data.Id)
            }}
          />
        )}
       </div>
      )},
    }
  ];

  const finalCols = [
    {
      headerName: "Finalized By",
      field: "fb",
      width: 200,
      wrapHeaderText: true,
    },
    {
      headerName: "Finalized Date Time",
      field: "ft",
      wrapHeaderText: true,
      width: 180,
      filter: "agDateColumnFilter",
      cellRenderer: ({ value }) => {
        const date = new Date(value);
        return !isNaN(date.getTime()) // Check if valid date
          ? date.toLocaleString("en-US", { timeZone: "UTC" })
          : "";
      },
    },
    {
      headerName: "County",
      field: "co",
      width: 80,
      maxWidth: 180,
      wrapHeaderText: true,
    },
    {
      headerName: "Instrument Number",
      field: "iid",
      width: 130,
      wrapHeaderText: true,
    },
    {
      headerName: "Recorded Date",
      field: "rd",
      width: 130,
      wrapHeaderText: true,
      valueFormatter: (params) => {
        if (!params.value) return "";
        const date = new Date(params.value);
        return `${(date.getMonth() + 1).toString().padStart(2, "0")}/${date.getDate().toString().padStart(2, "0")}/${date.getFullYear()}`;
      },
    },
    {
      headerName: "Error Description",
      field: "ed",
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "Comment",
      field: "cmt",
      minWidth: 350,
      flex: 1,
      wrapHeaderText: true,
    },
    {
      headerName: "",
      resizable: false,
      minWidth: 90,
      maxWidth: 90,
      cellRenderer: ({ data }) => {
        let Icon = MdEditNote;
        if(data.st === 'Final') Icon = MdOutlineRemoveRedEye;
        return (
          <div style={{marginTop:"5px"}}>
        <Icon
          className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
          onClick={() => {
            getInstrumentPdf({
              ...data,
              hd: true
            });
            getErrorReportData(data.Id);
            setShowModal(true);
          }}
        />
         {currentTab === "reportedError" && (
          <IoMdClose
            className={`btn-icon ${theme === "dark" ? "btn-light" : ""}`}
            onClick={() => {
              showConfirmationBox(data.Id)
            }}
          />
        )}
       </div>
      )},
    }
  ];

  const evenTypeHandler = (e, type) => {
    setFilters({
      ...filters,
      [type]: e,
    });
  };

  const showConfirmationBox = (Id) => {
    setModalHeader('Confirm Deletion');
    setModalBody('Are you sure you want to delete this Record?');
    setModalFooter(
        <div>
            <button
                className="btn btn-primary mr-3"
                style={{ width:"80px" }}
                onClick={() => {
                  deleteReportError(Id);
                  setShow(false);
                }}
                
            >
                Yes
            </button>
            {cancelButton}
        </div>
    );
    setShow(true);
};

const closeModal = () => {
  setShow(false);
}

const cancelButton = (
  <button className="btn btn-info" onClick={() => setShow(false)} style={{ width:"80px" }}>
    Cancel
  </button>
);

  return (
    <div className={`dashboard${theme === "dark" ? "-dark" : ""} d-flex flex-column`}>
      <NavBarContainer />
      <div className="content d-flex flex-column">
        <div className="search mb-1">
          <div className="d-flex">
            <div className="mt--5">
              <label>From</label>
              <input
                style={{
                  border: "1px solid #ced4da",
                  height: "38px",
                  width: "200px",
                }}
                type="date"
                name="fromdate"
                value={filters.fromdate}
                onChange={inputsHandler}
                className={`form-control bg-transparent date-filter input-calender ${theme === "dark" ? "dark-input" : ""}`}
              />
            </div>
            <div className="ml-3 mt--5">
              <label>To</label>
              <input
                style={{
                  border: "1px solid #ced4da",
                  height: "38px",
                  width: "200px",
                }}
                type="date"
                name="todate"
                value={filters.todate}
                onChange={inputsHandler}
                className={`form-control bg-transparent date-filter ${theme === "dark" ? "dark-input" : ""}`}
              />
            </div>
            <div className="ml-3 mt--5">
              <label>Username</label>
              <SelectViewNew
                className="filter"
                options={[{ username: "All" }, ...users].map((item) => ({
                  value: item.username,
                  label: item.username,
                }))}
                values={filters.userName.map((item) => ({
                  value: item,
                  label: item,
                }))}
                placeholder="Username"
                searchable
                onChange={(e) => evenTypeHandler(e, "userName")}
                width={300}
                theme={theme}
              />
            </div>
            <div className="ml-3 mt--5">
              <label>County</label>
              <SelectViewNew
                className="filter"
                options={["All", ...county].map((item) => ({
                  value: item,
                  label: item,
                }))}
                values={filters.countyA.map((item) => ({
                  value: item,
                  label: item,
                }))}
                placeholder="County"
                searchable
                onChange={(e) => evenTypeHandler(e, "countyA")}
                width={200}
                theme={theme}
              />
            </div>
            <div className="ml-3 mt--5">
              <label>Error Description</label>
              <SelectViewNew
                className="filter"
                options={[{ name: "All" }, ...reportReasons].map((item) => ({
                  value: item.name,
                  label: item.name,
                }))}
                values={filters.edA.map((item) => ({
                  value: item,
                  label: item,
                }))}
                placeholder="Error Description"
                searchable
                onChange={(e) => evenTypeHandler(e, "edA")}
                width={200}
                theme={theme}
              />
            </div>
          </div>
          <div className="user-btn mt-2">
            <button
              className="btn btn-info mr-3"
              onClick={() => {
                setFilters(defaultFilters);
              }}
            >
              Clear All
            </button>
            <button
              className="btn btn-primary"
              onClick={() => {
                const filterData = {
                  ...filters,
                  ed: filters.edA.join(","),
                  un: filters.userName.join(","),
                  co: filters.countyA.join(","),
                };
                getErrorLogList(filterData);
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div className="data d-flex flex-column">
          <Tabs defaultActiveKey="reportedError">
            <Tab
              eventKey="reportedError"
              title="Reported Errors"
              onEnter={() => setCurrentTab("reportedError")}
            >
              {loadingDiv}
              <div className="digital d-flex flex-column">
                <Table
                  cols={cols}
                  data={
                    errorLogList.filter((error) => error.st === "Reported") ??
                    []
                  }
                />
              </div>
            </Tab>
            <Tab
              eventKey="correctedError"
              title="Corrected"
              onEnter={() => setCurrentTab("correctedError")}
            >
              {loadingDiv}
              <div className="digital d-flex flex-column">
                <Table
                  cols={correctedCols}
                  data={
                    errorLogList.filter((error) => error.st === "Corrected") ??
                    []
                  }
                />
              </div>
            </Tab>
            <Tab
              eventKey="finalError"
              title="Final"
              onEnter={() => setCurrentTab("finalError")}
            >
              {loadingDiv}
              <div className="digital d-flex flex-column">
                <Table
                  cols={finalCols}
                  data={
                    errorLogList.filter((error) => error.st === "Final") ??
                    []
                  }
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
      <PdfModal
        type="Error"
        selectedPdf={selectedPdf}
        errorData={reportedErrorData}
        showModal={showModal}
        onHide={() => setShowModal(false)}
      ></PdfModal>
      <Modal
        showModal={show}
        header={modalHeader}
        html={modalBody}
        footerHtml={modalFooter}
        onHide={closeModal}
        theme={theme}
      />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorLogList: state.userLog.errorLogList,
    users: state.userLog.usersList,
    defaultFilters: state.userLog.defaultErrorFilters,
    loading: state.userLog.loading,
    theme: state.user.theme,
    county: state.advancedSearch.county,
    reportReasons: state.userLog.reportReasons,
    selectedPdf: state.advancedSearch.selectedPdf,
    reportedErrorData: state.advancedSearch.reportedErrorData,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getErrorLogList: (filters) => dispatch(getErrorLogList(filters)),
  getUsersList: () => dispatch(getUsersList()),
  getInstrumentPdf: (data) => dispatch(getInstrumentPdf(data)),
  getErrorReportData: (id) => dispatch(getErrorReportData(id)),
  getInstrumentTypes: () => dispatch(getInstrumentTypes()),
  deleteReportError: (Id) => dispatch(deleteReportError({ rid: Id })),
});

const ErrorLogContainer = connect(mapStateToProps, mapDispatchToProps)(ErrorLog);

export default ErrorLogContainer;
