import React, { useState } from 'react';
import { CgChevronUpO, CgChevronDownO } from 'react-icons/cg';

const dateRendrer = ({ value }) =>
  value instanceof Date
    ? value.toLocaleDateString("en-US", { timeZone: "UTC" })
    : "";

const ArrayToListRenderer = (params) => {
  const arr = params.value ? params.value.split(" | ") : [];
  if (Array.isArray(arr)) {
    return (
      <ul className="custom-list">
        {arr.map((item, index) => (
          <li key={index} className="custom-list-item">{item}</li>
        ))}
      </ul>
    );
  }
  return arr;
};

const linkRendrer = ({ data }) => {
  return data.vw ? (
    <a
      href={data.vw}
    >
      View
    </a>
  ) : (
    data.iid
  )
}

const dateRendrerNew = ({ value }) => {
  if (!value) return ""; // Handle null/undefined values
  const date = new Date(value); // Convert value to a Date object
  return !isNaN(date.getTime()) // Check if it's a valid date
    ? date.toLocaleDateString("en-US", { timeZone: "UTC" })
    : "";
};

export const TRSRenderer = ({ value }) => {
  const [expanded, setExpanded] = useState(false);

  const values = typeof value === "string" ? value.split("|").map((v) => v.trim()) : [];
  const firstValue = values[0] || "";
  const hasMoreContent = firstValue.length > 20 || values.length > 1;
  const displayedContent = expanded
    ? values
    : [firstValue.length > 20 ? firstValue.slice(0, 18): firstValue];
  const handleToggle = () => setExpanded((prev) => !prev);

  return (
    <div
      style={{
        whiteSpace: expanded ? "normal" : "nowrap",
        display: expanded ? "block" : "flex",
        alignItems: expanded ? "flex-start" : "center",
        lineHeight: expanded ? "1.5" : "",
        wordBreak: "normal",
        overflowWrap: "normal",
        marginTop: expanded ? "13px" : "",
        marginBottom: expanded ? "13px" : "",
      }}
    >
      {displayedContent.map((item, index) => (
        <div key={index} style={{ marginBottom: expanded ? "5px" : "0" }}>
          <span>{item}</span>
        </div>
      ))}
      {hasMoreContent && (
        <button
          onClick={handleToggle}
          style={{
            color: "blue",
            background: "none",
            border: "none",
            cursor: "pointer",
            marginTop: expanded ? "5px" : "0",
            display: "flex",
            alignItems: "center",
          }}
        >
          {expanded ? (
            <CgChevronUpO className="btn-icon" />
          ) : (
            <CgChevronDownO className="btn-icon" />
          )}
        </button>
      )}
    </div>
  );
};

export const headers = {
  regulatory: "Apps/Orders",
  permits: "Permits",
  spudnotice: "Spud Notices",
  completions: "Completions",
};

export const payloadType = {
  permits: {
    "co": "",
    "trs": "",
    "al": "",
    "op": "",
    "wna": "",
    "wnu": "",
    "api": "",
    "fn": "",
    "ll": "",
    "ad": "",
    "ed": "",
    "ns": "",
  },
  spudnotice: {
    "co": "",
    "trs": "",
    "al": "",
    "op": "",
    "wna": "",
    "wnu": "",
    "api": "",
    "sd": "",
    "ns": "",
  },
  completions: {
    "co": "",
    "trs": "",
    "al": "",
    "tda": "",
    "obd": "",
    "gmd": "",
    "op": "",
    "wna": "",
    "wnu": "",
    "api": "",
    "fn": "",
    "ll": "",
    "sd": "",
    "cd": "",
    "fd": "",
    "ns": "",
  },
  regulatory: {
    "dr": "",
    "co": "",
    "trs": "",
    "cl": "",
    "ap": "",
    "cn": "",
    "on": "",
    "ns": "",
  },
}

export const dailyReportTabs = [
  {
    name: "Apps/Orders",
    key: "regulatory",
    cols: [
      // {
      //   headerName: "View",
      //   field: "vw",
      //   width: 140,
      //   cellRenderer: linkRendrer,
      //   wrapHeaderText: true,
      // },
      {
        headerName: "Date",
        field: "dr",
        wrapHeaderText: true,
        width: 110,
        filter: "agDateColumnFilter",
        cellRenderer: dateRendrer,
        __metadata__: { type: "date" },
      },
      {
        headerName: "County",
        field: "co",
        width: 140,
        __metadata__: { type: "dropdown", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "TRS",
        field: "trs",
        width: 120,
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
        __metadata__: { type: "tags" },
      },
      {
        headerName: "Allocation",
        field: "alo",
        width: 120,
        cellRenderer: ArrayToListRenderer,
        __metadata__: { type: "tags" },
        wrapHeaderText: true,
      },
      {
        headerName: "Formation Name",
        field: "frm",
        width: 140,
        __metadata__: { type: "tags" },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Classification & Doc Title",
        field: "cl",
        // width: 140,
        flex: 1,
        wrapHeaderText: true,
        __metadata__: { type: "input" },
      },
      {
        headerName: "Applicant",
        field: "ap",
        // width: 140,
        flex: 1,
        wrapHeaderText: true,
        __metadata__: { type: "input" },
      },
      {
        headerName: "Case #",
        field: "cn",
        cellRenderer: ArrayToListRenderer,
        width: 150,
        wrapHeaderText: true,
        __metadata__: { type: "input" },
      },
      {
        headerName: "Order #",
        field: "on",
        width: 100,
        wrapHeaderText: true,
        __metadata__: { type: "input" },
      },
      {
        headerName: "Related Orders",
        field: "ro",
        width: 120,
        __metadata__: { type: "tags" },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Notes",
        field: "ns",
        width: 160,
        wrapHeaderText: true,
        __metadata__: { type: "textarea" },
      },
    ],
    data: [],
  },
  {
    name: "Permits",
    key: "permits",
    cols: [
      // {
      //   headerName: "View",
      //   field: "vw",
      //   width: 120,
      //   cellRenderer: linkRendrer,
      //   wrapHeaderText: true,
      // },
      {
        headerName: "County",
        field: "co",
        cellRenderer: ArrayToListRenderer,
        width: 140,
        __metadata__: { type: "dropdown", multiSelect: true },
        wrapHeaderText: true,
      },
      {
        headerName: "TRS",
        field: "trs",
        cellRenderer: ArrayToListRenderer,
        __metadata__: { type: "tags" },
        width: 150,
        // flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "Allocation",
        field: "al",
        width: 110,
        // flex: 1,
        cellRenderer: ArrayToListRenderer,
        __metadata__: { type: "tags" },
        wrapHeaderText: true,
      },
      {
        headerName: "Operator",
        field: "op",
        flex: 1,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "Well Name",
        field: "wna",
        __metadata__: { type: "input" },
        // width: 190,
        flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "Well Number",
        field: "wnu",
        __metadata__: { type: "input" },
        // width: 140,
        flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "API",
        field: "api",
        // width: 150,
        __metadata__: { type: "input" },
        flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "Formation Name",
        field: "fn",
        // width: 160,
        flex: 1,
        __metadata__: { type: "tags" },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Lateral Length",
        field: "ll",
        width: 60,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "Approved Date",
        field: "ad",
        wrapHeaderText: true,
        width: 120,
        filter: "agDateColumnFilter",
        __metadata__: { type: "date" },
        cellRenderer: dateRendrer,
      },
      {
        headerName: "Expiration Date",
        field: "ed",
        wrapHeaderText: true,
        width: 120,
        // flex: 1,
        __metadata__: { type: "date" },
        filter: "agDateColumnFilter",
        cellRenderer: dateRendrer,
      },
      {
        headerName: "Notes",
        field: "ns",
        width: 160,
        __metadata__: { type: "textarea" },
        wrapHeaderText: true,
      },
    ],
    data: [],
  },
  {
    name: "Spud Notices",
    key: "spudnotice",
    cols: [
      {
        headerName: "View",
        field: "vw",
        width: 120,
        cellRenderer: linkRendrer,
        wrapHeaderText: true,
      },
      {
        headerName: "Spud Date",
        field: "sd",
        wrapHeaderText: true,
        width: 140,
        filter: "agDateColumnFilter",
        __metadata__: { type: "date" },
        cellRenderer: ({ value }) =>
          value instanceof Date
            ? value.toLocaleDateString("en-US", { timeZone: "UTC" })
            : "",
      },
      {
        headerName: "County",
        field: "co",
        width: 160,
        __metadata__: { type: "dropdown", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "TRS",
        field: "trs",
        width: 150,
        __metadata__: { type: "tags", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Allocation",
        field: "al",
        width: 110,
        __metadata__: { type: "tags", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Operator",
        field: "op",
        flex: 1,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "Well Name",
        field: "wna",
        // width: 190,
        flex: 1,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "Well Number",
        field: "wnu",
        // width: 120,
        flex: 1,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "API",
        field: "api",
        // width: 150,
        flex: 1,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
    ],
    data: [],
  },
  {
    name: "Completions",
    key: "completions",
    cols: [
      {
        headerName: "County",
        field: "co",
        width: 120,
        __metadata__: { type: "dropdown", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "TRS",
        field: "trs",
        __metadata__: { type: "tags", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        width: 150,
        wrapHeaderText: true,
      },
      {
        headerName: "Allocation",
        field: "al",
        width: 110,
        __metadata__: { type: "tags", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Test Date",
        field: "tda",
        wrapHeaderText: true,
        width: 70,
        __metadata__: { type: "date" },
        filter: "agDateColumnFilter",
        cellRenderer: dateRendrer,
      },
      {
        headerName: "Oil (BBL/Day)",
        field: "obd",
        width: 110,
        __metadata__: { type: "tags", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Gas (Mcf/Day)",
        field: "gmd",
        __metadata__: { type: "tags", multiSelect: true },
        width: 110,
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Operator",
        field: "op",
        width: 150,
        __metadata__: { type: "input" },
        // cellStyle: { lineHeight: '30px'},
        wrapHeaderText: true,
      },
      {
        headerName: "Well Name",
        field: "wna",
        width: 140,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "Well Number",
        field: "wnu",
        width: 110,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "API",
        field: "api",
        width: 140,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      {
        headerName: "Formation Name",
        field: "fn",
        width: 170,
        __metadata__: { type: "tags", multiSelect: true },
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Lateral Length",
        field: "ll",
        width: 60,
        __metadata__: { type: "input" },
        wrapHeaderText: true,
      },
      // {
      //   headerName: "Permit Date",
      //   field: "pd",
      //   wrapHeaderText: true,
      //   width: 80,
      //   filter: "agDateColumnFilter",
      //   cellRenderer: dateRendrer,
      // },
      {
        headerName: "Spud Date",
        field: "sd",
        wrapHeaderText: true,
        width: 80,
        __metadata__: { type: "date" },
        filter: "agDateColumnFilter",
        cellRenderer: dateRendrer,
      },
      {
        headerName: "Completion Date",
        field: "cd",
        wrapHeaderText: true,
        width: 110,
        __metadata__: { type: "date" },
        filter: "agDateColumnFilter",
        cellRenderer: dateRendrer,
      },
      {
        headerName: "First Production Date",
        field: "fd",
        wrapHeaderText: true,
        width: 110,
        filter: "agDateColumnFilter",
        __metadata__: { type: "date" },
        cellRenderer: dateRendrer,
      },
      {
        headerName: "Notes",
        field: "ns",
        width: 160,
        wrapHeaderText: true,
        __metadata__: { type: "textarea" },
      },
    ],
    data: [],
  },
  // {
  //   name: "Applications",
  //   key: "applications",
  //   cols: [
  //     {
  //       headerName: "View",
  //       field: "vw",
  //       width: 80,
  //       cellRenderer: linkRendrer,
  //       wrapHeaderText: true,
  //     },
  //     {
  //       headerName: "County",
  //       field: "co",
  //       width: 180,
  //       wrapHeaderText: true,
  //     },
  //     {
  //       headerName: "TRS",
  //       field: "trs",
  //       cellRenderer: ArrayToListRenderer,
  //       width: 140,
  //       wrapHeaderText: true,
  //     },
  //     {
  //       headerName: "Classification & Doc Title",
  //       field: "cl",
  //       // width: 140,
  //       flex: 1,
  //       wrapHeaderText: true,
  //     },
  //     {
  //       headerName: "Applicant",
  //       field: "ap",
  //       // width: 140,
  //       flex: 1,
  //       wrapHeaderText: true,
  //     },
  //     {
  //       headerName: "Case #",
  //       field: "cn",
  //       width: 160,
  //       cellRenderer: ArrayToListRenderer,
  //       wrapHeaderText: true,
  //     },
  //     {
  //       headerName: "Date",
  //       field: "dr",
  //       wrapHeaderText: true,
  //       width: 120,
  //       filter: "agDateColumnFilter",
  //       cellRenderer: dateRendrer,
  //     },
  //   ],
  //   data: [],
  // },
 
  {
    name: "Well Transfers",
    key: "welltransfers",
    cols: [
      {
        headerName: "View",
        field: "vw",
        width: 100,
        wrapHeaderText: true,
        cellRenderer: linkRendrer,
      },
      {
        headerName: "Transfer Date",
        field: "td",
        wrapHeaderText: true,
        width: 150,
        filter: "agDateColumnFilter",
        cellRenderer: dateRendrer,
      },
      {
        headerName: "County",
        field: "co",
        cellRenderer: ArrayToListRenderer,
        width: 140,
        wrapHeaderText: true,
      },
      {
        headerName: "TRS",
        field: "trs",
        width: 140,
        cellRenderer: ArrayToListRenderer,
        wrapHeaderText: true,
      },
      {
        headerName: "Well Name",
        field: "wna",
        width: 180,
        wrapHeaderText: true,
      },
      {
        headerName: "Well Number",
        field: "wnu",
        width: 180,
        wrapHeaderText: true,
      },
      {
        headerName: "API",
        field: "api",
        width: 140,
        wrapHeaderText: true,
      },
      {
        headerName: "From Operator",
        field: "fo",
        // width: 140,
        flex: 1,
        wrapHeaderText: true,
      },
      {
        headerName: "To Operator",
        field: "to",
        // width: 140,
        flex: 1,
        wrapHeaderText: true,
      },
    ],
    data: [],
  },
];



export const wellCols = [
  {
    headerName: "Status",
    field: "ws",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "County",
    field: "co",
    wrapHeaderText: true,
    width:120,
  },
  {
    headerName: "TRS",
    field: "trs",
    wrapHeaderText: true,
    width: 140,
    // minWidth: 100,
    wrapText: true, autoHeight: true,
    cellRenderer: TRSRenderer,
  },
  {
    headerName: "Allocations",
    field: "al",
    wrapHeaderText: true,
    width: 100,  
  },
  {
    headerName: "Well Name",
    field: "wna",
    wrapHeaderText: true,
    width: 230, 
  },
  {
    headerName: "Well Number",
    field: "wnu",
    wrapHeaderText: true,
    width: 200,
  },
  {
    headerName: "Operator",
    field: "op",
    wrapHeaderText: true,
    width: 300,
    wrapText: true, autoHeight: true,
  },
  {
    headerName: "Formation",
    field: "fm",
    wrapHeaderText: true,
    width: 200,
    wrapText: true, autoHeight: true,
    cellRenderer: TRSRenderer,

  },
  {
    headerName: "Prod Type",
    field: "pt",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "Well Type",
    field: "wt",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "Permit Date",
    field: "dp",
    wrapHeaderText: true,
    width: 80,
    cellRenderer: dateRendrerNew,
  },
  {
    headerName: "Spud Date",
    field: "ds",
    wrapHeaderText: true,
    width: 80,
    cellRenderer: dateRendrerNew,
  },
  {
    headerName: "Completion Date",
    field: "dc",
    wrapHeaderText: true,
    width: 80,
    cellRenderer: dateRendrerNew,
  },
  {
    headerName: "Ip Date",
    field: "di",
    wrapHeaderText: true,
    width: 80,
    cellRenderer: dateRendrerNew, 
  },
  {
    headerName: "First Prod Date",
    field: "df",
    wrapHeaderText: true,
    width: 80,
    cellRenderer: dateRendrerNew, 
  },
  {
    headerName: "Ip Gas",
    field: "ipg",
    wrapHeaderText: true, 
    width: 80,
  },
  {
    headerName: "Ip Oil",
    field: "ipo",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "Gas Cum",
    field: "gc",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "Oil Cum",
    field: "oc",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "Months Produced",
    field: "mp",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "Lateral Length",
    field: "ll",
    wrapHeaderText: true,
    width: 80,
  },
  {
    headerName: "API",
    field: "api",
    wrapHeaderText: true,
    width: 160,
  },
];
