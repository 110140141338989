import { handleActions } from "redux-actions";
// import moment from 'moment';
import moment from 'moment-timezone';

const dateFormatTag = 'MM/DD/YYYY';
const xDays = 1;
const fromDate = moment(new Date(new Date().setDate(new Date().getDate() - xDays))).tz("UTC");
const toDate = moment(new Date()).tz("UTC");
const dateFormatFilter = 'Y-MM-DD';
const colors = ['#3bb2d0', '#569C1B', '#f2c80f', '#de6418', '#04D5A0', '#fbb03b', '#8ad4eb', '#bece7d', '#f2c80f', '#de6418', '#e55e5e', '#fbb03b'];
const defaultColor = '#3bb2d0';
const multipleColor = '#de7fa1'; 
const defaultState = {
    // __persist: true,
    isFirstLoad: true,
    instrumentList: [],
    prevInstrumentList: [],
    instrumentTypes: [],
    instrumentTypesConveyance: [],
    instrumentTypesLease: [],
    instrumentPDFs: {},
    trsm: [],
    geojson: [],
    prevGeoJson: [],
    previousFilters: [],
    county: [
        'ADAIR',
        'ALFALFA',
        'ATOKA',
        'BEAVER',
        'BECKHAM',
        'BLAINE',
        'BRYAN',
        'CADDO',
        'CANADIAN',
        'CARTER',
        'CHEROKEE',
        'CHOCTAW',
        'CIMARRON',
        'CLEVELAND',
        'COAL',
        'COMANCHE',
        'COTTON',
        'CRAIG',
        'CREEK',
        'CUSTER',
        'DELAWARE',
        'DEWEY',
        'ELLIS',
        'GARFIELD',
        'GARVIN',
        'GRADY',
        'GRANT',
        'GREER',
        'HARMON',
        'HARPER',
        'HASKELL',
        'HUGHES',
        'JACKSON',
        'JEFFERSON',
        'JOHNSTON',
        'KAY',
        'KINGFISHER',
        'KIOWA',
        'LATIMER',
        'LE FLORE',
        'LINCOLN',
        'LOGAN',
        'LOVE',
        'MAJOR',
        'MARSHALL',
        'MAYES',
        'MCCLAIN',
        'MCCURTAIN',
        'MCINTOSH',
        'MURRAY',
        'MUSKOGEE',
        'NOBLE',
        'NOWATA',
        'OKFUSKEE',
        'OKLAHOMA',
        'OKMULGEE',
        'OSAGE',
        'OTTAWA',
        'PAWNEE',
        'PAYNE',
        'PITTSBURG',
        'PONTOTOC',
        'POTTAWATOMIE',
        'PUSHMATAHA',
        'ROGER MILLS',
        'ROGERS',
        'SEMINOLE',
        'SEQUOYAH',
        'STEPHENS',
        'TEXAS',
        'TILLMAN',
        'TULSA',
        'WAGONER',
        'WASHINGTON',
        'WASHITA',
        'WOODS',
        'WOODWARD'
    ],
    defaultFilters: {
        // from_date: moment(new Date('2023-01-01')).format("Y-MM-DD"),
        from_date: fromDate.format(dateFormatFilter),
        to_date: toDate.format(dateFormatFilter),
        gg_condition: 'AND',
        county: [],
        instrument_type: [],
        grantees: [],
        grantors: [],
        book: '',
        page: '',
        ggTogether: [],
        trs: '',
        isDateFiltered: false,
        isGGSeparate: false,
    },
    filterData: {},
    tags: [
        {
          id: 'from_date',
          text: fromDate.format(dateFormatTag)
        },
        { id: 'to_date', text: toDate.format(dateFormatTag) }
      ],
    ggOperator: ['OR', 'AND'],
    selectedCounty: [],
    countyRelations: [],
    township: [
      '01N',
      '01S',
      '02N',
      '02S',
      '03N',
      '03S',
      '04N',
      '04S',
      '05N',
      '05S',
      '06N',
      '06S',
      '07N',
      '07S',
      '08N',
      '08S',
      '09N',
      '09S',
      '10N',
      '10S',
      '11N',
      '11S',
      '12N',
      '13N',
      '14N',
      '15N',
      '16N',
      '17N',
      '18N',
      '19N',
      '20N',
      '21N',
      '22N',
      '23N',
      '24N',
      '25N',
      '26N',
      '27N',
      '28N',
      '29N'
    ],
    range: [
      '01E',
      '01W',
      '02E',
      '02W',
      '03E',
      '03W',
      '04E',
      '04W',
      '05E',
      '05W',
      '06E',
      '06W',
      '07E',
      '07W',
      '08E',
      '08W',
      '09E',
      '09W',
      '10E',
      '10W',
      '11E',
      '11W',
      '12E',
      '12W',
      '13E',
      '13W',
      '14E',
      '14W',
      '15E',
      '15W',
      '16E',
      '16W',
      '17E',
      '17W',
      '18E',
      '18W',
      '19E',
      '19W',
      '20E',
      '20W',
      '21E',
      '21W',
      '22E',
      '22W',
      '23E',
      '23W',
      '24E',
      '24W',
      '25E',
      '25W',
      '26E',
      '26W',
      '27E',
      '27W',
      '28E'
    ],
    meridian: [ 'IM', 'CM' ],
    selectedPdf: null,
    pdfComments: [],
    colorMapping: {},
    error: null,
    reportedErrorData: null,
    // gridFilterTrigger: 0,
    loading: false,
    ordersLoading: false,
    regulatoryAppsOrders: [],
    prevRegulatoryAppsOrders: [],
    wellData: [],
    prevWellData: [],
    wellLoading: false,
    lastWorkingTags: [
        {
          id: 'from_date',
          text: fromDate.format(dateFormatTag)
        },
        { id: 'to_date', text: toDate.format(dateFormatTag) }
      ],
};
defaultState.filterData = {...defaultState.defaultFilters};

const setGeoJsonWithColor = (instrumentList, grantees, colorMapping) => {
    const granteesArray = grantees.split(", ");
    const geojson = [];
    const uniqueLongLat = {};
    instrumentList.forEach((item) => {
        const granteeColors = {};
        granteesArray.forEach(grantee => {
            if((item.ge?.toLowerCase().includes(grantee.toLowerCase())) || item.gr?.toLowerCase().includes(grantee.toLowerCase())) {
                granteeColors[grantee] = colorMapping[grantee];
            }
        });
        const coordinates = item.lglt ? [...new Set(item.lglt.split("|"))] : [];
        coordinates.forEach((cord) => {
            const granteeColorKeys = Object.keys(granteeColors);
            let color;
            if(granteeColorKeys.length > 1) {
                color = multipleColor;
            } else {
                color = granteeColors[granteeColorKeys[0]];
            }
            // multiple records exists for one lat, long. So if even a single record has multiple color, that should be show on map
            if (cord in uniqueLongLat && uniqueLongLat[cord] !== color) {
                uniqueLongLat[cord] = multipleColor; // Use multipleColor if multiple colors are present
            } else {
                uniqueLongLat[cord] = color;
            }
            const [lg, lt] = cord.split(",");
            geojson.push({
                type: 'Feature',
                properties: {
                    // ...item,
                    color: uniqueLongLat[cord] ?? defaultColor
                },
                geometry: {
                    type: 'Point',
                    coordinates: [lg, lt]
                }
            });
        });
    });
    return geojson;
}

const setGeoJsonWithoutColor = (instrumentList) => {
    const geojson = [];
    instrumentList.forEach((item) => {
        const coordinates = item.lglt ? [...new Set(item.lglt.split("|"))] : [];
        coordinates.forEach((cord) => {
            const [lg, lt] = cord.split(",");
            geojson.push({
                type: 'Feature',
                properties: {
                    // ...item,
                    color: defaultColor
                },
                geometry: {
                    type: 'Point',
                    coordinates: [lg, lt]
                }
            });
        });
    });
    return geojson;
}

export default handleActions(
    {
        ADVANCED_SEARCH_REQUEST: (state) => {
            return {
                ...state,
                instrumentList: [],
                loading: true
            };
        },

        ADVANCED_SEARCH_SUCCESS: (state, { payload: { instrumentList } }) => {
            instrumentList = instrumentList.map((i) => ({
                ...i,
                dr: i.dr ? new Date(i.dr) : null,
                expand: false
            }));
            return {
                ...state,
                loading: false,
                instrumentList
            }
        },

        ADVANCED_SEARCH_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                loading: false,
                error: err,
            };
        },

        ADVANCED_SEARCH_NEW_REQUEST: (state, { payload: { filters }}) => {
            const isEmptyFilters = Object.keys(filters)
            .filter(key => 
                ['book', 'county', 'from_date', 'to_date', 'grantees', 'grantors', 'instrument_type', 'page', 'trs']
                .includes(key)
            )
            .every(key => filters[key] === '' || filters[key] === null);
    
            // if not filters are set, date will take default values
            if (isEmptyFilters) {
                const toDate = new Date();
                const fromDate = new Date();
                fromDate.setDate(toDate.getDate() - xDays);
        
                filters.from_date = fromDate.toISOString().split('T')[0];
                filters.to_date = toDate.toISOString().split('T')[0];
            }
            
            return {
                ...state,
                prevInstrumentList: state.instrumentList,
                prevRegulatoryAppsOrders: state.regulatoryAppsOrders,
                prevWellData: state.wellData,
                prevGeoJson: state.geojson,
                instrumentList: [],
                colorMapping: {},
                geojson: [],
                loading: true,
                regulatoryAppsOrders: [],
                wellData: [],
            };
        },

        ADVANCED_SEARCH_NEW_SUCCESS: (state, { payload: { payload: { filters }, instrumentList } }) => {
            // const gridFilterTrigger = state.gridFilterTrigger + 1;
            instrumentList = instrumentList.map((instrument, index) => ({
                ...instrument,
                index,
                dr: instrument.dr ? new Date(instrument.dr) : null,
                trs: instrument.trs ? [...new Set(instrument.trs.split(" | "))].join("|") : '',
                expand: false
            }));
            let applyColors = false;
            const nameColorMapping = {};
            if(filters.gg_condition === 'OR' && filters.grantees !== "") {
                applyColors = true;
                filters.grantees.split(', ').forEach((el, index) => {
                    nameColorMapping[el] = colors[index];
                });
                if(filters.grantees.split(', ').length > 1) {
                    nameColorMapping['Multiple'] = multipleColor;
                }
            }
            let geojson = [];
            if(applyColors) {
                geojson = setGeoJsonWithColor(instrumentList, filters.grantees, nameColorMapping);
            } else {
                geojson = setGeoJsonWithoutColor(instrumentList);
            }
            const lastWorkingTags = [...state.tags];
            return {
                ...state,
                loading: false,
                instrumentList,
                colorMapping: nameColorMapping,
                prevGeoJson: [],
                prevInstrumentList: [],
                // gridFilterTrigger,
                geojson,
                lastWorkingTags
            }
        },

        ADVANCED_SEARCH_NEW_FAILURE: (state, { payload: err }) => {
            if(err?.err?.code === "ERR_CANCELED") {
                err.err.message = "Request has been cancelled";
            }
            return {
                ...state,
                instrumentList: state.prevInstrumentList,
                geojson: state.prevGeoJson,
                regulatoryAppsOrders: state.prevRegulatoryAppsOrders,
                wellData: state.prevWellData,
                prevGeoJson: [],
                prevInstrumentList: [],
                prevRegulatoryAppsOrders: [],
                prevWellData: [],
                loading: false,
                error: err,
            };
        },

        INSTRUMENT_TYPE_LIST_REQUEST: (state) => {
            return {
                ...state,
                instrumentTypes: [],
            };
        },

        INSTRUMENT_TYPE_LIST_SUCCESS: (state, { payload: { instrumentTypes } }) => {
            const instTypes = instrumentTypes.map((item) => item.instrument_type);
            const instTypesConveyance = instrumentTypes.filter((item) => item.is_conveying_doc).map((item) => item.instrument_type);
            const instTypesLease = instrumentTypes.filter((item) => item.is_lease).map((item) => item.instrument_type);
            return {
                ...state,
                instrumentTypes: instTypes,
                instrumentTypesConveyance: instTypesConveyance,
                instrumentTypesLease: instTypesLease
            }
        },

        INSTRUMENT_TYPE_LIST_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                loading: false,
                error: err,
            };
        },

        GET_ADV_INST_DOCUMENT_REQUEST: (state, { payload: { instrument } }) => {
            return {
                ...state,
                selectedPdf: {
                    instrument
                },
                instrumentPDFs: {
                    ...state.instrumentPDFs,
                    [instrument.iid]: {},
                },
            };
        },

        GET_ADV_INST_DOCUMENT_SUCCESS: (
            state,
            { payload: { instrument, pdfData } }
        ) => {
            let instrumentPDFs = state.instrumentPDFs;
            const iPDF = window.URL.createObjectURL(
                new Blob([pdfData], { type: "application/pdf" })
            );
            const instrumentPdf = {
                pdf: iPDF,
                instrument: instrument,
                isEditing: false
            };
            if (instrumentPDFs.hasOwnProperty(instrument.iid))
                instrumentPDFs = {
                    ...instrumentPDFs,
                    [instrument.iid]: instrumentPdf
                };
            return {
                ...state,
                instrumentPDFs,
                selectedPdf: instrumentPdf,
                error: "",
            };
        },

        GET_ADV_INST_DOCUMENT_FAILURE: (state, { payload: { err, instrument} }) => {
            let instrumentPDFs = state.instrumentPDFs;
            if(Object.keys(instrumentPDFs).includes(instrument.iid)) {
                delete instrumentPDFs[instrument.iid];
            }
            return {
                ...state,
                loading: false,
                instrumentPDFs,
                error: err,
            };
        },

        GET_ERROR_REPORT_DATA_REQUEST: (state) => {
            return {
                ...state,
                reportedErrorData: null
            };
        },

        GET_ERROR_REPORT_DATA_SUCCESS: (
            state,
            { payload }
        ) => {
            for (const key in payload) {
                if(['rt', 'ct', 'ft', 'ide', 'rd', 'ede', 'ide'].includes(key) && payload[key] !== null) {
                    payload[key] =  new Date(payload[key]);
                }
                if(['gr', 'ge'].includes(key)) {
                    payload[key] = payload[key] === null ? [] : payload[key].split(' | ').filter(item => item !== '');
                }
                if(key === 'trs') {
                    payload[key] = payload[key] === null ? [] : payload[key].split(' | ').filter(item => item !== '');
                }
                payload['ed'] = Array.isArray(payload['ed']) ? payload['ed'].filter(data => data !== null).join(', ') : payload['ed'];
            }
            return {
                ...state,
                loading: false,
                reportedErrorData: payload
            };
        },

        GET_ERROR_REPORT_DATA_FAILURE: (state, { payload: { err } }) => {
            return {
                ...state,
                loading: false,
                error: err,
            };
        },

        DOWNLOAD_ADV_INST_DOCUMENT_REQUEST: (state, { payload: { instrument } }) => {
            return {
                ...state,
                selectedPdf: {
                    instrument
                },
                instrumentPDFs: {
                    ...state.instrumentPDFs,
                    [instrument.iid]: {},
                    pdfComments: [],
                },
            };
        },

        DOWNLOAD_ADV_INST_DOCUMENT_SUCCESS: (
            state,
            { payload: { instrument, pdfData } }
        ) => {
            let instrumentPDFs = state.instrumentPDFs;
            const iPDF = window.URL.createObjectURL(
                new Blob([pdfData], { type: "application/pdf" })
            );
            const instrumentPdf = {
                pdf: iPDF,
                instrument: instrument,
                isEditing: false
            };
            if (instrumentPDFs.hasOwnProperty(instrument.iid)) {
                instrumentPDFs = {
                    ...instrumentPDFs,
                    [instrument.iid]: instrumentPdf
                };
            }
            let instrumentList = state.instrumentList;
            instrumentList = instrumentList.map(inst => {
                if (inst.iid === instrument.iid) {
                    inst.hd = true;
                }
                return inst;
            });
            return {
                ...state,
                instrumentPDFs,
                instrumentList,
                selectedPdf: instrumentPdf,
                error: "",
            };
        },

        REMOVE_ADV_TAB: (state, { payload: { instrumentId } }) => {
            const instrumentPDFs = state.instrumentPDFs;
            delete instrumentPDFs[instrumentId];
            return {
                ...state,
                instrumentPDFs: {
                    ...instrumentPDFs,
                },
            };
        },

        SET_ADVANCED_ROW_HEIGHT_AND_MODE: (state, { payload: { index, height, isExpand } }) => {
            let instruments = state.instrumentList;
            instruments = instruments.map(instrument => {
                if (instrument.index === index) {
                    instrument.height = height;
                    instrument.expand = isExpand;
                }
                return instrument;
            });
            return {
                ...state,
                instrumentList: instruments
            }
        },

        SET_TRSM_REQUEST: (state, { payload: { trsm } }) => {
            // trsm = trsm.toUpperCase().replace(/[^0-9NSEWICM,\-\s;]/g, '');
            return {
                ...state,
                trsm
            };
        },

        SET_TAGS: (state, { payload: { filters } }) => {
            const tags = [];
            if(filters.from_date) {
                tags.push({
                    id: 'from_date',
                    text: moment(filters.from_date).format(dateFormatTag)
                });
            }
            if(filters.to_date) {
                tags.push({
                    id: 'to_date',
                    text: moment(filters.to_date).format(dateFormatTag)
                });
            }

            if(filters.grantors && filters.isGGSeparate) {
                filters.grantors.forEach(gg => {
                    tags.push({
                        id: 'grantors',
                        text: gg
                    });
                });
            }
            if(filters.grantees && filters.isGGSeparate) {
                filters.grantees.forEach(gg => {
                    tags.push({
                        id: 'grantees',
                        text: gg
                    });
                });
            }
            if(filters.ggTogether && !filters.isGGSeparate) {
                filters.ggTogether.forEach(gg => {  
                    tags.push({
                        id: 'ggTogether',
                        text: gg
                    });
                });
            }
            if(filters.instrument_type) {
                filters.instrument_type.forEach(inst => {
                    tags.push({
                        id: 'instrument_type',
                        text: inst
                    });
                });
            }
            if(filters.county) {
                filters.county.forEach(cnt => {
                    tags.push({
                        id: 'county',
                        text: cnt
                    });
                });
            }
            if(filters.trs) {
                filters.trs.forEach(trs => {
                    tags.push({
                        id: 'trs',
                        text: trs
                    });
                });
            }
            if(filters.book) {
                tags.push({
                    id: 'book',
                    text: filters.book
                });
            }
            if(filters.page) {
                tags.push({
                    id: 'page',
                    text: filters.page
                });
            }
            return {
                ...state,
                tags
            }
        },

        SET_TAGS_TO_PREVIOUS_FILTER: (state) => {
            const tags = [...state.lastWorkingTags];
            return {
                ...state,
                tags
            }
        },

        REMOVE_TAG: (state, { payload: { index } }) => {
            const tags = state.tags;
            const removedTag = tags.splice(index, 1)[0];
            const filterData = state.filterData;
            let trsm = state.trsm;
            if(['book', 'page'].includes(removedTag.id)) {
                filterData[removedTag.id] = '';
            } else if(removedTag.id === 'trs') {
                trsm = trsm.filter(el => el !== removedTag.text);
            } else if(['county', 'instrument_type', 'ggTogether', 'grantors', 'grantees'].includes(removedTag.id)) {
                filterData[removedTag.id] = filterData[removedTag.id].filter(el => el !== removedTag.text)
            }
            return {
                ...state,
                tags: [...tags],
                trsm,
                filterData
            }
        },

        FETCH_PREVIOUS_FILTERS_REQUEST: (state) => {
            return {
                ...state,
                previousFilters: []
            }
        },

        FETCH_PREVIOUS_FILTERS_SUCCESS: (state, { payload }) => {
            return {
                ...state,
                previousFilters: payload
            }
        },

        FETCH_PREVIOUS_FILTERS_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                loading: false,
                error: err,
            };
        },

        UPDATE_FILTER_DATA: (state, { payload: { filters }}) => {
            return {
                ...state,
                filterData: filters
            }
        },

        SET_PREVIOUS_VALUE_IN_FILTER: (state, { payload: index }) => {
            let filterData = {...state.defaultFilters};
            if(state.previousFilters[index]?.search_data) {
                try {
                    const parsedData = JSON.parse(state.previousFilters[index].search_data);
                    filterData = {
                        ...parsedData,
                        county: parsedData.county === '' ? [] : parsedData.county.split(","),
                        instrument_type: parsedData.instrument_type === '' ? [] : parsedData.instrument_type.split(","),
                        grantees: parsedData.grantees === '' ? [] : parsedData.grantees.split(","),
                        grantors: parsedData.grantors === '' ? [] : parsedData.grantors.split(","),
                        isGGSeparate: parsedData.gg_condition === 'AND',
                        isDateFiltered: parsedData.isDateFiltered ?? false,
                    };
                    filterData['ggTogether'] = parsedData.gg_condition === 'AND' ? [] : filterData.grantees;
                } catch (e) {
                    console.log('Invalid search data found');
                }
            }
            const trsm = filterData.trs === '' ? [] : filterData.trs.split(',');
            return {
                ...state,
                filterData,
                trsm
            }
        },

        ADD_FILTER_IN_PREV_FILTERS: (state, { payload: filters}) => {
            // check if same filter
            return {
                ...state,
                previousFilters: [{ search_data: filters } , ...state.previousFilters],
            }
        },

        CLEAR_FILTER_DATA: (state) => {
            return {
                ...state,
                filterData: defaultState.defaultFilters
            }
        },

        RESET_TAGS: (state) => {
            const tags = defaultState.tags;
            return {
                ...state,
                tags
            };
        },

        GET_PDF_COMMENTS_REQUEST: (state, { payload }) => {
            return {
                ...state,
                pdfComments: []
            }
        },

        GET_PDF_COMMENTS_SUCCESS: (state, { payload }) => {
            const pdfComments = payload.map(i => ({
                ...i,
                rt: i.rt ? new Date(i.rt) : null,
            }));
            return {
                ...state,
                pdfComments
            }
        },

        GET_PDF_COMMENTS_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                error: err,
            };
        },

        ADD_PDF_COMMENTS_SUCCESS: (state, { payload }) => {
            const pdfComments = state.pdfComments;
            const updatedComments = [...pdfComments, {
                username: payload.username,
                cmt: payload.cmt,
                rt: new Date()
            }];
            return {
                ...state,
                pdfComments: updatedComments
            }
        },

        ADD_PDF_COMMENTS_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                error: err,
            };
        },

        REPORT_ERROR_PDF_REQUEST: (state) => {
            return {
                ...state,
                // loading: true
            }
        },

        REPORT_ERROR_PDF_SUCCESS: (state) => {
            return {
                ...state,
                // loading: false
            }
        },

        REPORT_ERROR_PDF_FAILURE: (state, { payload: err }) => {
            return {
                ...state,
                // loading: false,
                error: err
            }
        },

        SET_FIRST_LOAD_VALUE: (state, payload) => {
            return {
                ...state,
                isFirstLoad: false
            }
        },

        CLEAR_ALL_DATA: () => {
            return {
                ...defaultState,
            };
        },

        CHANGE_MARKER_COLOR: (state)=>{
            // const geojson =   setGeoJsonWithoutColor(state.instrumentList)
            return{
                ...state,
                // geojson,
            }
        },

        GET_REGULATORY_APPS_ORDERS_DATA_REQUEST_NEW: (state) => {
            return {
              ...state,
              regulatoryAppsOrders: [],
              ordersLoading: true,
              error: null,
            };
        },  

        GET_REGULATORY_APPS_ORDERS_DATA_REQUEST: (state) => {
            return {
              ...state,
              regulatoryAppsOrders: [],
              ordersLoading: true,
              error: null,
            };
        },  
      
        GET_REGULATORY_APPS_ORDERS_DATA_SUCCESS: (state, { payload: { regulatoryAppsOrders } }) => {
            return {
              ...state,
              ordersLoading: false,
              regulatoryAppsOrders, 
            };
        },
      
        GET_REGULATORY_APPS_ORDERS_DATA_FAILURE: (state, { payload: error }) => {
            return {
              ...state,
              ordersLoading: false,
              error, 
            };
        },

        GET_WELL_DATA_REQUEST: (state) => {
            return {
              ...state,
              wellData: [],
              wellLoading: true,
              error: null,
            };
        },
      
        GET_WELL_DATA_SUCCESS: (state, { payload: { wellData } }) => {
            return {
              ...state,
              wellData,
              wellLoading: false,
              error: null,
            };
        },
      
        GET_WELL_DATA_FAILURE: (state, { payload: { err } }) => {
            return {
              ...state,
              wellLoading: false,
              error: err,
            };
        },
        
    },
    defaultState
);
