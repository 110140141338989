/**
 * User Management Sagas
 */
import { all, takeEvery, call, put } from "redux-saga/effects";
import { toast } from "react-toastify";
import * as types from "../actions/types";
import * as api from "../apis/instrument";

export function* getUsersList() {
  try {
    const users = yield call(api.doGetUsersList);
    if (users) {
      yield put({
        type: types.GET_USERS_LIST_SUCCESS,
        payload: users,
      });
    } else {
      yield put({
        type: types.GET_USERS_LIST_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_USERS_LIST_FAILURE,
      payload: { err },
    });
  }
}

export function* getOkcrUsersList() {
  try {
    const users = yield call(api.doGetOkcrUsersList);
    if (users) {
      yield put({
        type: types.GET_OKCR_USERS_LIST_SUCCESS,
        payload: users,
      });
    } else {
      yield put({
        type: types.GET_OKCR_USERS_LIST_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.GET_OKCR_USERS_LIST_FAILURE,
      payload: { err },
    });
  }
}

export function* updateUserDetails({ payload: { userDetails } }) {
  try {
    const resData = yield call(userDetails.Id ? api.doUpdateUser : api.doAddUser, userDetails);
    if (resData) {
      // yield put({
      //   type: types.UDPATE_USER_SUCCESS,
      //   payload: { userDetails: resData[0] },
      // });
      yield put({
        type: types.GET_USERS_LIST_REQUEST,
        payload: { },
      });
      toast.success(`User details ${userDetails.Id ? 'udpated' : 'added'} successfully`);
    } else {
      yield put({
        type: types.UPDATE_USER_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    yield put({
      type: types.UPDATE_USER_FAILURE,
      payload: { err },
    });
  }
}

export function* updateUserPassword({ payload: { userDetails } }) {
  try {
    const resData = yield call(api.doResetPassword, userDetails);
    if (!resData[0]) {
      yield put({
        type: types.UPDATE_USER_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    } else {
      toast.success("User password updated successfully");
    }
  } catch (err) {
    yield put({
      type: types.UPDATE_USER_FAILURE,
      payload: { err },
    });
  }
}

export function* inviteUser({ payload }) {
  try {
    const resData = yield call(api.doInviteUser, payload);
    if (!resData.success) {
      yield put({
        type: types.SEND_INVITE_TO_USER_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    } else {
      toast.success("User Invited successfully");
    }
  } catch (err) {
    const errorMessage = err.response?.data?.error || "An unexpected error occurred";
    yield put({
      type: types.SEND_INVITE_TO_USER_FAILURE,
      payload: { err: { message: errorMessage } },
    });
    // toast.error(errorMessage);
  }
}

export function* deleteUser({ payload: { userDetails } }) {
  try {
    const resData = yield call(api.doDeleteUser, userDetails);
    if (resData[0]) {
      yield put({
        type: types.DELETE_USER_SUCCESS,
        payload: { userDetails },
      });
      toast.success("User deleted successfully");
    }
  } catch (err) {
    yield put({
      type: types.DELETE_USER_FAILURE,
      payload: { err },
    });
  }
}

function* updateMyPassword({ payload }) {
  // let errorHandled = false;
  try {
    const response = yield call(api.handleUpdatePassword, payload.userDetails);
    if (response) {
      yield put({
        type: types.UPDATE_MY_PASSWORD_SUCCESS,
      });
      toast.success("Password updated successfully.");
    } else {
      // Handle unsuccessful response
      yield put({
        type: types.UPDATE_MY_PASSWORD_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
    // Only handle the error once
    // if (!errorHandled) {
      yield put({
        type: types.UPDATE_MY_PASSWORD_FAILURE,
        payload: { err: { message: err.response?.data?.message || 'Password could not be updated' } },
      });
    //   errorHandled = true; // Set a flag to prevent rehandling
    // }
  }
}

export function* updateUserInfo({ payload }) {
  try {
    const response = yield call(api.handleUpdateUserInfo, payload.userDetails);
    if (response) {
      yield put({
        type: types.UPDATE_USER_INFO_SUCCESS,
        payload: payload.userDetails,
      });
      toast.success("User information updated successfully.");
    } else {
      yield put({
        type: types.UPDATE_USER_INFO_FAILURE,
        payload: { err: { message: "Something went wrong" } },
      });
    }
  } catch (err) {
      yield put({
        type: types.UPDATE_USER_INFO_FAILURE,
        payload: { err: { message: err.response?.data?.message || 'User information could not be updated' } },
      });
    
  }
}
// User Management Saga
export default function* userManagementSaga() {
  yield all([
    takeEvery(types.GET_USERS_LIST_REQUEST, getUsersList),
    takeEvery(types.GET_OKCR_USERS_LIST_REQUEST, getOkcrUsersList),
    takeEvery(types.UDPATE_USER_REQUEST, updateUserDetails),
    takeEvery(types.UPDATE_USER_PASSWORD_REQUEST, updateUserPassword),
    takeEvery(types.DELETE_USER_REQUEST, deleteUser),
    takeEvery(types.SEND_INVITE_TO_USER_REQUEST, inviteUser),
    takeEvery(types.UPDATE_MY_PASSWORD_REQUEST, updateMyPassword),
    takeEvery(types.UPDATE_USER_INFO_REQUEST, updateUserInfo),
  ]);
}
